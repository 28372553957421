import React, {createContext, useContext , useState} from 'react'



const LevelSelectionContext = createContext();

export default function LevelSelectionProvider({children}) {
     const [selectedLevel, setSelectedLevel] = useState("")
     
  return (
    <LevelSelectionContext.Provider value={[selectedLevel, setSelectedLevel]}>
    {children}
    </LevelSelectionContext.Provider>
  )
}

export function useLevelSelection(){
    return useContext(LevelSelectionContext)
}
