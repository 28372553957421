import React from 'react'
import {AiOutlineCheck} from "react-icons/ai"
import {Link} from "react-router-dom"
import "./courses.css"
import Footer from "../footer/Footer"
import CourseContent from './course-content'
import AdvancedCourseData from "./AdvancedCourseContentData"




export default function AdvancedCourse({props, courseData, CourseContentHeader}) {


  return (
    <div>
        <div className="course">
            <div className="course-description">
                <h2 className="course-description-header">Course Description</h2>
                <h3 className="what-you-would-learn-header">At the end of this course, students will have basic knowledge and demonstrate understanding of the following</h3>
                <div className="learning-outcome">
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Understand the connection between Web2 and Web3</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Understand Social Finanace and its usecases </li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Learn and understand the difference NFTs and fungible tokens</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Metavers and its usecases</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Blockchain in finance</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">What is smart contracts and how to read them</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Decentralised identities and their Apps</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Careers in blockchain</li></div>
                </div>  
            </div>
        </div>
        <CourseContent
        CourseContentHeader = "Advanced Course -(60 hours)"
        courseData= {AdvancedCourseData}
        />
    </div>
  )
}
