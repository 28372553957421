import React, { useEffect } from 'react'
// import Footer from '../footer/Footer'
import { Link } from 'react-router-dom'
import "./sign-up.css"
import { useState } from 'react'
import { PhoneAuthProvider, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import auth from "../../utils/firebase"
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar/navbar'
import { useLevelSelection } from '../enroll/LevelSelectionContext'
import { useLoading, useUser } from '../../utils/FirestoreContext'
import ClipLoader from 'react-spinners/ClipLoader'
// import { UserCredential } from 'firebase/auth'

const override = {
  display: "block",
  margin: "300px auto",
  borderColor: "#8B03FB",
};



export default function Signup() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")  
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState()
  const [phone, setPhone] = useState("")
  const [user, enrollUser] = useUser()
  const [selectedLevel] = useLevelSelection()
  const [loading, setLoading] = useLoading()
  const [emailSignUpError, setEmailSignUpError] = useState("")
  const [reRoutToDashboard, setreRoutToDashboard] = useState(false)
  // const fetchUserData = useFetchData()
  // const [selectedLevel, setSelectedLevel] = useLevelSelection()

  const navigate = useNavigate()
  const EmailSignUp = async (e) => {
    e.preventDefault()
    if(password.length >= 8){
      try {
        setLoading(true)
          await createUserWithEmailAndPassword(auth, email, password, phone).then(async (userCredential) =>  {
              const user = userCredential.user;
              updateProfile(user, {
                displayName : `${firstName} ${lastName}`,
                phoneNumber : phone
              })
              console.log(userCredential)
          })
  
          console.log("user registered successfully");
          setreRoutToDashboard(true)
  
      } catch (error) {
        setEmailSignUpError(error.message)
        console.log(error)
      }finally{
        setLoading(false)
      }
    }else{
      console.log('password must be atleast 8 characters long')
      setPasswordError(true)
    }

}




  if(reRoutToDashboard){
    navigate('/profile')
  }



  return (
    <div>
       {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (
        <>
        <div className="signup">
            <form className='signup-form' onSubmit={EmailSignUp}>
              <div className="first-and-lastname"> 
              <div className="firstName-group"> 
              <span className='firstName-label' htmlFor="name">First Name:</span>
              <input type="text"  className="firstname" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}} id="first-name" placeholder="First Name"/>
              </div>
              <div className="lastName-group">
              <span className='lastName-label' htmlFor="name">Last Name:</span>
              <input type="text" value={lastName}  onChange={(e) => (setLastName(e.target.value))} className="lasttname" id="last-name" placeholder="Last Name"/>
              </div>
              </div>
              <label htmlFor="email" className='email-label'>Email:</label>
              <input type="email"  className="email" value={email} onChange={(e)=>(setEmail(e.target.value))} placeholder="blockbit@gmail.com"/>
              <label htmlFor="phone" className='phone-label'>Phone:</label>
              <input type="phone"  className="phone" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="+234 7062748739"/>
              <label htmlFor="phone" className='email-label'>Password:</label>
              <input type="password"  className="password" value={password} onChange={(e)=>(setPassword(e.target.value))} placeholder="*******"/>
              <>
                {passwordError && <p className='password-error'>Password must be at least 8 characters</p>}
                {emailSignUpError && <p className='password-error'>{emailSignUpError}</p>}
              </>
              <button type="submit" className="submit">Enroll</button>
            </form>
            <div className="alternative">
                <p className="login-question">Already have an account <Link to="/login">Log in?</Link></p>
            </div>
        </div>
        {/* <div className="error-display">{error}</div> */}
        </>)}
    </div>
  )
}


