// import React, { useState } from "react";
// import Saly from "./images/saly.svg"
import Saly from "./images/salyPNG.png"
import "./home-page.css"
import Navbar from "../Navbar/navbar";
import CourseProgression from "../courses/CourseProgression";
import axios from 'axios'
import { useState } from "react";
import Alert from '@mui/material/Alert';
import ClipLoader from "react-spinners/ClipLoader";
import { useLoading } from '../../utils/FirestoreContext';
import {Helmet} from 'react-helmet'
import logo from './images/logo.svg'




const override = {
    display: "block",
    margin: "100px auto",
    borderColor: "#8B03FB",
  };

  

export default function Home (){
    const [loading, setLoading] = useLoading()
    const [email, setEmail] = useState('');
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(undefined)

   

    const handleSubscribe = async (e) =>{
        e.preventDefault()
        setLoading(true)
        try {
            await axios.post("https://blockbit.institute/subscribe", {email: email});
            setIsSuccess(true)
            setIsError(false)
            console.log(`submitted from the client side!`);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setIsSuccess(false)
            setIsError(true)
            console.log(`error subscribing to newsletter: ${error}`)
        }
    }

    return (
        <div>
        <Helmet>
        <title>Blockbit Institute | Your Personalised Blockchain Institute</title>
        
        {/* General meta tags */}
        <meta name="description" content="Your personalised person to person institute. A platform to learn all things Web3." />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="blockchain, blockchain courses, blockchain certification, learn blockchain, crypto enthusiats, Learn crypto, make money from crypto, get certified, get a blockchain certificate" />
        <meta name="author" content="Blockbit Institute" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Language" content="en" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content="Blockbit Institute | Your Personalised Blockchain Institute" />
        <meta property="og:description" content="Your personalised person to person institute. A platform to learn all things Web3." />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://www.blockbit.institute" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content={logo} />
        <meta name="twitter:title" content="Blockbit Institute | Your Personalised Blockchain Institute" />
        <meta name="twitter:description" content="Your personalised person to person institute. A platform to learn all things Web3." />
        <meta name="twitter:image" content="https://www.blockbit.institute" />
      </Helmet>
            <div className="home-screen-wrapper">
            <Navbar/>

            <div className="hero-section">
                <h2 className="hero-header">A Personalized Person<br/> to Person Blockchain Institute</h2>
                <p className="hero-details">A platform to train and educate students on all things Web3.
                </p>
                <div className="circle-home"></div>
            </div>
            <div className="newsletter" id="newsletter">
                <div className="newsletter-card">
                  {loading? (<ClipLoader size={100} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (<>
                    <div className="newsletter-content" >
                        <h2 className="newsletter-header">Blockchain News</h2>
                        <p className="newsletter-details"> 
                        Get daily news about what is happening around the world
                        concerning blockchain and crypto currencies.
                        </p>
                        <form className="newsletter-input" onSubmit={handleSubscribe}>
                        
                            <input className="email-input" id="email" type="email" placeholder="Email address" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                            <button className="submit-button-homepage" type="submit">Subcribe</button>
                            {isSuccess && (<Alert severity="success">Successfully subscribed.</Alert>)}
                            {isError && (<Alert severity="error">Failed to subscribe.</Alert>)}
                        </form>
                    </div>
                    <div className="newsletter-pic-wrapper">
                        <img src={Saly} alt="newsletter pic"/>
                    </div>
                    </>)}   
                </div>
            </div>
            </div>
            <CourseProgression/>
        </div>
    )
}