import React from 'react'
import { Link } from "react-router-dom"
import Basic from "./images/basicPNG.png"
import Intermediate from "./images/intermediatePNG.png"
import Advanced from "./images/advancedPNG.png"
import "../home/home-page.css"




export default function CourseProgression() {
  return (
    <div>

       <div className="progression-section">
                <h2 className="progression-header">Progression</h2>
            <div className="progression">
                <div className="basic-card">
                    <div className="basic-image-wrapper">
                        <img src={Basic} alt="basic level card"/>
                    </div>
                    <h2 className="progression-title">Basic</h2>
                    <p className="progression-detials">Learn the basics of blockchain </p>
                    <Link to={"/enroll-basic"}><button className="veiw-courses-btn" id="basic-btn">View course</button></Link>
                </div>
                <div className="intermediate-card">
                    <div className="intermediate-image-wrapper">
                        <img src={Intermediate}  alt="intermediate level card"/>
                    </div>
                    <h2 className="progression-title">Intermediate</h2>
                    <p className="progression-detials">Continue as an Intermidiate</p>
                    <Link to={"/enroll-intermediate"}><button className="veiw-courses-btn" id="intermediate-btn">View course</button></Link>
                </div>
                <div className="advanced-card">
                    <div className="advanced-image-wrapper">
                        <img src={Advanced} alt="advanced level card"/>
                    </div>
                    <h2 className="progression-title">Advanced</h2>
                    <p className="progression-detials">Continue as an Advanced </p>
                    <Link to={"/enroll-advanced"}><button className="veiw-courses-btn" id="advanced-btn">View course</button></Link>
                </div>
            <div className="progression-circle1"></div>
            <div className="progression-circle2"></div>
            
            </div>
        </div> 
    </div>
  )
}
