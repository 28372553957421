import React, { useState } from 'react'
import Navbar from '../Navbar/navbar'
import "./blog.css"
import { doc, setDoc  } from 'firebase/firestore'
import auth, { db, storage } from '../../utils/firebase'
import Resizer from "react-image-file-resizer";
import ClipLoader from "react-spinners/ClipLoader";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';



const override = {
  display: "block",
  margin: "300px auto",
  borderColor: "#8B03FB",
};

export default function PostBlog() {
  const [blogHeaderImage, setBlogHeaderImage ] = useState()
  const [blogHeader, setBlogHeader ] = useState("")
  const [blogBodyText, setBlogBodyText ] = useState("")
  const authUser = auth.currentUser
  const [loading, setLoading] = useState(false)






  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });


  const handleSubmit = async () => {
    try {
      if(blogHeaderImage){
        const userId = authUser.uid
        const storageRef = ref(storage, `blog-pics/${userId}/${userId}.jpg`);
        uploadBytes(storageRef, blogHeaderImage).then((snapshot) =>{
        })


        // for logically deleting images before upload
        // storageRef !== null
        // ? deleteObject(storageRef).then(() => {
        //     uploadBytes(storageRef, blogHeaderImage)
        //       .then(() => {
        //         console.log("file uploaded");
        //       })
        //       .catch((err) => {
        //         console.log(`this error is from the update after delete and here it is: ${err}`);
        //       });
        //   })
        // : uploadBytes(storageRef, blogHeaderImage);

      var blogImageUrl = await getDownloadURL(ref(storageRef))
      console.log(blogImageUrl)
      }


      setLoading(true)
      if(blogBodyText, blogHeader ==  ""){
        console.log("fill in the boxes")
        return
      }else{
        // adminUid = authUser.uid
        const blogRef = doc(db, "blogPosts", authUser.uid)
        await setDoc(blogRef, 
          {
            blogHeaderImageUrl: blogImageUrl,
            blogHeader: blogHeader,
            blogBodyText: blogBodyText,
            author: auth.currentUser,

          } )
        setLoading(false)
      }  
      
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }


  return (
    <div className='post-blog'>
      <Navbar/>
      {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) :(<>
        <h2 className='edit-blog-header'>Edit Your Blog</h2>
        <div className='edit-post'>
          <input type="file" className='blog-header-background-image' accept='image/*'   
          onChange={ async (e) => {
            try {
              const file = e.target.files[0];
              setBlogHeaderImage(await resizeFile(file))
              // console.log(pic);
            } catch (err) {
              console.log(err);
            }}}/>
          <div>
          <label>Enter Blog Header Here</label>
          <input type="text" className='blog-header' value={blogHeader}  onChange={(e)=>{setBlogHeader(e.target.value)}}/>
          </div>
          <div className='blog-body-div'>
          <label>Enter Blog Body Here</label>
          <textarea type="text" className='blog-body' value={blogBodyText} onChange={(e)=>{setBlogBodyText(e.target.value)}}/>
          </div>
          <button className='submit-blog-post' onClick={(handleSubmit)}>Submit</button>
        </div>
        </>)}
    </div>
  )
}
