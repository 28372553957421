import React, { useEffect, useState } from 'react'
import Facebook from "./images/Facebook.svg"
import Youtube from "./images/Youtube.svg"
import LinkedIn from "./images/linkedin.svg"
import Twitter from "./images/Twitter.svg"
import github from "./images/github.svg"
import "../footer/footer.css"
import { Link } from 'react-router-dom'
import auth from '../../utils/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import axios from 'axios'

export default function  Footer() {
    const user = auth.currentUser
    const [email, setEmail] = useState('')


    const handleEmailSubmit = async (e)=>{
        e.preventDefault()
        try{
            axios.post('https://blockbit.institute/subscribe', {email : email})
            console.log("submitted from cleint side!")

        }catch(err){
            console.log(`error subscribing to newsletter: ${err}`);
        }
    }


  return (
    <div>

        <div className="footer-wrapper">
            <div className="footer">
              <div className='links-group'> 
                <div className="connect-with-us">
                    <ul className="our-socials">
                    <li className="social"><Link className="connect-facebook"><img src={Facebook} alt="facebook-icon" className="facebook-icon" /></Link></li>
                    <li className="social"><Link className="connect-linkedin" to="https://www.linkedin.com/company/blockbit-institute/"><img src={LinkedIn} alt="facebook-icon" className="linkdin-icon" /></Link></li>
                    <li className="social"><Link className="connect-youtube"><img src={Youtube} alt="facebook-icon" className="youtube-icon" /></Link></li>
                    <li className="social"><Link className="connect-twitter" to="https://x.com/blockbit_i"><img src={Twitter} alt="facebook-icon" className="twitter-icon" /></Link></li>
                    <li className="social"><Link className="connect-github"><img src={github} alt="facebook-icon" className="github-icon" /></Link></li>
                    </ul>
                </div>

                <div className="footer-navigation">
                    <div className="footer-nav-links">
                        <Link to="/course">Course</Link>
                        <Link>Alumni</Link>
                        <Link to="/profile">Profile</Link>
                        <Link>Mentors</Link>
                    </div>

                    {!user?
                    <>
                        <div className="log-or-sign">
                        <Link to="./login" className="footer-login">Login</Link>
                        <span className="question">Or</span>
                        <Link to="./signup"  className="footer-signup">signUp</Link>
                        </div>
                    </> : ""}    
                </div>
              </div>    
                <div className="subscribe-to-news-footer">
                    <h2 className="footer-newsletter-header">Subscribe to our newsletter</h2>
                    <form className="footer-newsletter" onSubmit={handleEmailSubmit} >
                        <input type="email" className="footer-email-input" id="footer-newsletter" placeholder="Email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                        <button type="submit" className="submit-button">Subcribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
