import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./course-content.css"
import {MdArrowDropDown} from "react-icons/md"



const basicCourseContentData = [
  {
    header : "Introduction to Web3",
  },
  {
    header : "Introduction to Blockchain",
    learningOutcome : [
       "History of money",
       "Meaning of blockchain",
       "Types of blockchain",
       "Examples of blockchain (focus on Bitcoin)"
    ]
  },  
  {
    header : "Meaning of Cryptocurrency",
    learningOutcome : [
       "Types of Cryptocurrency",
       "Meaning of blockchain",
       "Examples of Cryptocurrency (Give assignment. Identify 5 Cryptocurrency and their blockchain. Identify 5 crypto token and their protocol)",
    ]
  },  
  {
    header : "Centralized Finance (Cefi)",
    learningOutcome : [
       "Meaning of Cefi",
       "Examples of Cefi platforms -(Centralized lending platforms and centralized exchanges.)"
    ]
  },  
  {
    header : "Decentralised Finance (Defi)",
    learningOutcome : [
       "Meaning of Defi",
       "Assignment (discuss the history of Defi and the founder of Defi)",
       "Examples of Defi platforms (exchanges, lending)",
    ]
  }
]

export default function CourseContent({props, courseData, CourseContentHeader}) {
  
  


  // const [elementIds, setElementIds] = useState({
  //   element1:"course-list-close",
  //   element2:"course-list-close",
  //   element3:"course-list-close",
  //   element4:"course-list-close",
  //   element5:"course-list-close",
  //   element6:"course-list-close",
  //   element7:"course-list-close",
  //   element8:"course-list-close",})

  // const changeId = (elementName) => {
  //   setElementIds((prevState) => {
  //     const currentId = prevState[elementName];
  //     const newId = currentId === "course-list-close" ? "course-list-open" : "course-list-close";
  //     return { ...prevState, [elementName]: newId}
  //   })
  // }


  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [veiwAll, setViewAll] = useState(false)

const handleDropdownToggle = (index) => {
  setOpenDropdownIndex((prevIndex) =>
    prevIndex === index ? null : index
  );
};

const courseItem = courseData.map((item, index) => (
  <React.Fragment key={index}>
    <div className="intro-toblockchain">
      <div className="header-and-drop-btn">
        <h2>{item.header}</h2>
        <MdArrowDropDown
          className={`drop-down-button-${
            openDropdownIndex === index ? 'open' : 'close'
          }`}
          onClick={() => handleDropdownToggle(index)}
        />
      </div>
      <hr/>
      {openDropdownIndex === index || veiwAll ?  (
        <ul className="course-list" >
          {Array.isArray(item.learningOutcome) &&
            item.learningOutcome.map((outcome, key) => (
              <React.Fragment key={key}>
                <span><li>{outcome}</li></span>
              </React.Fragment>
            ))}
        </ul>
      ): ""}
    </div>
  </React.Fragment>
));

const handleViewAll = ()=>{
  setViewAll(!veiwAll)
}

  return (
    <div className="course-content-wraper">
      <div className="course-content">
        <div className="headers">
          <h2 className="course-content-heading">{CourseContentHeader}</h2>
          <Link className="view-all-cta" onClick={handleViewAll}>View All</Link>
        </div>
                  
        <div className="each-section">
        {courseItem}
        </div> 
      </div>
    </div>
  )}
