import React, { useState } from 'react'
import './popup.css'
import Cancel from './images/cancel-icon.svg'


export default function PopUp() {
  const [cancel, setCancel] = useState(true)


  const handleCancel = ()=>{
    setCancel(false)
  }

  return (
    <div>
      {cancel && <div className="pop-up-card">
          <img src={Cancel} alt="cancel icon" className='cancel' onClick={handleCancel}/>
          <p className="pop-up-info"> <strong>Hey there!👋</strong> <br/><br/>Registration for this level is currently closed. Please wait for the next batch. Thank you for your understanding!<br/><br/> To get first-hand information when the next session begins, subscribe to our newsletter<a href='/#newsletter' className='popup-redirect'>(here)</a> In the meantime, feel free to explore this page to see what you'll learn or check out our other offerings.</p>
        </div>}
    </div>
  )
}
