import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/navbar'
import Footer from '../footer/Footer'
// import { User } from 'firebase/auth'
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from '../../utils/firebase';
// import { useNavigate } from 'react-router-dom';
import { db } from '../../utils/firebase';
import { useAvlUser, useFetchData, useLoading, useUser } from '../../utils/FirestoreContext';
import { useLevelSelection } from './LevelSelectionContext';
import "./enroll.css"
import Signup from '../auth/signup';
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom';
import AdvancedCourse from '../courses/AdvancedCourse';
import PopUp from './popup';





const override = {
  display: "block",
  margin: "300px auto",
  borderColor: "#8B03FB",
};


export default function EnrollAdvance() {
  const [authUser] = useAuthState(auth);
  const [user, enrollUser] = useUser();
  const [selectedLevel, setSelectedLevel] = useLevelSelection();
  const [enrollmentError, setEnrollmentError] = useState("");
  const [avlUser, setAvlUser] = useAvlUser();
  const [loading, setLoading] = useLoading();
  const fetchUserData = useFetchData();
  const [userData, storedUserData] = useState();
  const navigate = useNavigate()
  const [showPopUp, setShowPopUp] = useState(false)


    useEffect(()=>{
      setSelectedLevel("Advance");
      // setAvlUser(avlUser)
      // console.log(avlUser)
    }, [selectedLevel])
      
      // setUserIsEnrolled(true)
      
      const handleEnrollment = async () => {
        if(!selectedLevel) {
          setEnrollmentError("please select a level")
          return;
        };
        setLoading(true)
        await enrollUser(db).then((() => {
          fetchUserData()
          console.log(localStorage.getItem('userData'))
          // localStorage.setItem('userData', JSON.stringify(avlUser))
          console.log(avlUser)
          // setAvlUser(avlUser)
          navigate("/profile")
        }))
        setLoading(false)
      };
  
  
      useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
          console.log(storedUserData);
          try {
            setAvlUser(JSON.parse(storedUserData));
            console.log(avlUser);
            setLoading(true)
          } catch (error) {
            console.error("Error parsing stored user data:", error);
            // Handle the error, perhaps remove the invalid data from localStorage
            localStorage.removeItem('userData');
          }
        } else {
          fetchUserData(); // Fetch the data if not found in localStorage
        }
        setLoading(false)
      },[])


      useEffect(() =>{
        setShowPopUp(true)
      }, [PopUp])
  

    
      return (
        <div>
          {showPopUp && (<PopUp/>)}
          {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (
          <>
              <Navbar />
                <>
                  <div className="enroll-advance">
                    <h2 className="enroll-advance-header">Advance Level Enrollment</h2>
                  </div>
                  <AdvancedCourse/>
                </>
              {/* {!authUser ? (
                <Signup />
              ) :  (
                <div className={loading ? "none" : "enroll-button-wrapper"}>
                  {avlUser ? "" :(<button onClick={() => { handleEnrollment(); }}
                   className="enroll-advanced-btn"
                  >
                    Enroll Advance
                  </button>)}
                </div>
              )} */}
            <Footer />  
          </>
          )}
        </div>
      );  
}
