import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar/navbar'
import Footer from '../footer/Footer'
import mrT from './images/mr-t.svg'
import mrAbu from './images/mr-abu.svg'
import mrPaul from './images/mr-paul.svg'
import mrDominion from './images/dominion.svg'
import mrKeno from './images/mr-keno.svg'
import mrMartins from './images/mr-martins.svg'
import mrsIfy from './images/ify.svg'
import mrHenry from './images/mr-henry.svg'
import twitter from './images/twitter.svg'
import linkedin from './images/Linked.svg'
import './mentors.css'

export default function Mentors() {
  return (
    <div>
        <Navbar/>
            <div className="mentors">
                <h2 className="mentors-header">BlockBit Mentors</h2>
                <div className="mentors-wrapper">
                    <div className="each-mentor">
                        <img src={mrT} alt="Mr Toboray" className='mentor-image' />
                        <h4 className="mentor-name">Agidotan Efetobore Emmanuel</h4>
                        <p className="mentor-experience">Market Research / Public Relations / Project Marketing</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrDominion} alt="Mr dominion" className='mentor-image' />
                        <h4 className="mentor-name">Okocha Dominion</h4>
                        <p className="mentor-experience">Chief Marketing Officer/Community Moderator</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrMartins} alt="Mr MArtins" className='mentor-image' />
                        <h4 className="mentor-name">Emamowho Martins</h4>
                        <p className="mentor-experience"> Marketing Lead/ Community Management /Brand Growth Specailist</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrsIfy} alt="Mr Toboray" className='mentor-image' />
                        <h4 className="mentor-name">Nwabuzor Esther</h4>
                        <p className="mentor-experience">Bussiness Development/Marketing </p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrHenry} alt="Mr Henry" className='mentor-image' />
                        <h4 className="mentor-name">Henry Onyebuchi</h4>
                        <p className="mentor-experience"> Senior Software engineer/ Smart Contract Developer</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrAbu} alt="Mr Abu" className='mentor-image' />
                        <h4 className="mentor-name">Abu Emmanuel</h4>
                        <p className="mentor-experience">Bussiness Development Manager/ Community Manager</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrPaul} alt="Mr Paul" className='mentor-image' />
                        <h4 className="mentor-name">Paul Thomas Ifegwu</h4>
                        <p className="mentor-experience">Operations Manager / Project Manager</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="each-mentor">
                        <img src={mrKeno} alt="Mr Keno" className='mentor-image' />
                        <h4 className="mentor-name">Keno</h4>
                        <p className="mentor-experience">Senior Man</p>
                        <div className="mentor-social">
                            <Link><img src={twitter} alt="twitter" /></Link>
                            <Link><img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>
    </div>
  )
}
