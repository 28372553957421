import React from 'react'
import {AiOutlineCheck} from "react-icons/ai"
import {Link} from "react-router-dom"
import "./courses.css"
import Footer from "../footer/Footer"
import CourseContent from './course-content'
import BasicCourseData from './BasicCourseContentData'



export default function BasicCourse({props, courseData, CourseContentHeader}) {


  return (
    <div>
        <div className="course">
            <div className="course-description">
                <h2 className="course-description-header">Course Description</h2>
                <h5 className="what-you-would-learn-header">At the end of this course, students will have basic knowledge and demonstrate understanding of the following</h5>
                <div className="learning-outcome">
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">The concept of Web3 and its application</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">The meaning of blockchain and its use cases.</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">How cryptocurrencies work</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">How to navigate the world of centralised and Decentralised finance</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">Learn how to navigate Decentralised Apps and thier relevance</li></div>
                </div>
            </div>
        </div>
        <CourseContent
        CourseContentHeader = "Basic Course 18 hours -(2 Weeks)"
        courseData={BasicCourseData}
        />
    </div>
  )
}
