import React, { createContext, useContext, useEffect, useState } from "react";
import { collection, addDoc, setDoc, getDoc, doc, updateDoc, documentId} from "firebase/firestore"; 
import { auth, db, firestore } from "./firebase"; // Import Firebase setup
import { useLevelSelection } from "../pages/enroll/LevelSelectionContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import Login from "../pages/auth/login";


const UserContext = createContext();
const EnrollmentContext = createContext();
const LoadingContext = createContext();
const FetchUSerDataContext = createContext()
const AvlUserContext = createContext();


export default function FirestoreProvider({children}) {
    const [selectedLevel, setSelectedLevel] = useLevelSelection()
    const [user, setUser] = useState(null)
    const [userIsEnrolled, setUserIsEnrolled] = useState(true)
    const auth = getAuth();
    const authUser = auth.currentUser  //this is the signed-in user    
    const navigate = useNavigate()
    const [avlUser, setAvlUser] = useState(null) // updates after the fetchUserData is done, if the user data is present meaning there a user and returns the useData otherwise setAvlUser to null
    const [loading, setLoading] = useState(false);



    useEffect(() =>{
        // listen for firebase auth changes
        const unsuscribe = auth.onAuthStateChanged((authUser) => {
          if (authUser){
            setUser(authUser);
          }else{
            setUser(null)
          }
        });
        return () => {
        unsuscribe();
        };
    }, []);


    const enrollUser = async () => {
      if (!user) {
        return;
      }
  
      try {
        setLoading(true);
        const uid = authUser.uid;
        const docRef = doc(db, "users", uid);
        await setDoc(
          docRef,
          {
            email: authUser.email,
            username: authUser.displayName,
            photo: authUser.photoURL,
            level: selectedLevel,
            id: authUser.uid,
            isEnrolled: userIsEnrolled,
            mobile: authUser.phoneNumber,
            bio: "",
          },
          { merge: true }
        );
        console.log("Document written with ID: ", docRef.id);
  
        // Fetch user data after writing to Firestore
      } catch (error) {
        console.error("Error enrolling user:", error);
        // Set loading to false when there's an error
        setLoading(false);
      }
    };


      const fetchUserData = async () => {
        // setLoading(true)

        if (!user) {  
          console.log("user not authenticated");
          setLoading(false)
          return
        }
        
        try {
          const  docRef = doc(db, 'users', authUser.uid); // Replace 'your-user-id' with the actual user ID
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            // User data exists in Firestore
          setAvlUser(docSnap.data());
          const storedData = localStorage.setItem('userData', JSON.stringify(docSnap.data()))
          console.log(storedData);
          } else {
            // User data does not exist in Firestore
            setAvlUser(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setAvlUser(null);
        } finally {
          setLoading(false); // Set loading to false when data is fetched or if there's an error
        }
      };







  return (
    <UserContext.Provider value={[user, enrollUser]}>
    <EnrollmentContext.Provider value={[userIsEnrolled, setUserIsEnrolled]}>
      <LoadingContext.Provider value={[loading, setLoading]}>
        <FetchUSerDataContext.Provider value={fetchUserData}>
        <AvlUserContext.Provider value={[avlUser, setAvlUser]}>
        {children}
        </AvlUserContext.Provider>
        </FetchUSerDataContext.Provider>
      </LoadingContext.Provider>
    </EnrollmentContext.Provider>
  </UserContext.Provider>
);
}

export const useUser = () => useContext(UserContext);
export const useEnrollment = () => useContext(EnrollmentContext);
export const useLoading = () => useContext(LoadingContext);
export const useFetchData = () => useContext(FetchUSerDataContext)
export const useAvlUser = () => useContext(AvlUserContext)
