import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/navbar';
// import Login from '../auth/login';
import ProfilePic from './images/profile.png';
import "./profile.css";
// import { useEffect } from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth, db, storage} from '../../utils/firebase';
import { Link, useNavigate } from 'react-router-dom';
import { useAvlUser, useLoading } from '../../utils/FirestoreContext';
import { useFetchData } from '../../utils/FirestoreContext';
import {AiOutlineEdit} from 'react-icons/ai'
import { collection, updateDoc, doc} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {storage as firebaseStorage} from '../../utils/firebase'
import ClipLoader from "react-spinners/ClipLoader";
import { Navigate } from 'react-router-dom';
import { updateProfile } from 'firebase/auth';
import Resizer from "react-image-file-resizer";
// import Signup from '../auth/signup';


const override = {
  display: "block",
  margin: "300px auto",
  borderColor: "#8B03FB",
};



export default function Profile() {
  const navigate = useNavigate()
  const [user]= useAuthState(auth);
  // const fetchUserData = useFetchData()
  const [avlUser, setAvlUser] = useAvlUser()
  const [editBio, setEditBio] = useState(false)
  const [bioData, setBioData] = useState("Briefly describe yourself")
  const [editProfilePicture, setEditProfilePicture] = useState(false)
  const [pic, setPic] = useState(null)
  const [loading, setLoading] = useLoading()
  const fetchUserData = useFetchData()
  const authUser = auth.currentUser
  // const Navigate = useNavigate()



  const toggleBioEdit = ()=> {
    setEditBio(!editBio)
  }

  const togglePhotoEdit = () =>{
    setEditProfilePicture(!editProfilePicture)
  }



  const handleBioSubmit = async (e)=>{
    e.preventDefault();
    try{
      setLoading(true)
      if(bioData){
        const docRef = doc(db, 'users', authUser.uid)
        await updateDoc(docRef, {
          bio: bioData
        }).then(()=>{
          fetchUserData()
          setEditBio(false)
        
        })
      }else{
        console.log("enter a bio data")
      }
      setLoading(false)
    }catch(error){
      console.log("error updating" + error);
    }
    setLoading(false)
  }


//resize images

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

    // const handleFileChange = async (e) => {
    //   try {
    //     const file = e.target.files[0];
    //     setPic(await resizeFile(file))
    //     console.log(image);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };




  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      if(pic){
        const storageRef = ref(storage, 'profile-pics/' + authUser.uid +'.jpg');
        uploadBytes(storageRef, pic).then((snapshot) =>{
        })

      const imageUrl = await getDownloadURL(ref(storageRef))
      console.log(imageUrl)

        // uplaod path to firestore
        const  docRef = doc(db, 'users', authUser.uid)
        await updateDoc( docRef, {
        photo : imageUrl
      }).then(async ()=>{
       await updateProfile(user, {
          photoURL: imageUrl
        })
      })
      await fetchUserData()
      setEditProfilePicture(false)
      setLoading(false);
      }else{
        console.log('No picture selected');
        setLoading(false);
      }
    } catch (error) {
      console.log("error updating document" + error)
      setLoading(false)
    }
  }

  // useEffect(() =>{

  //   const fetchData = async () =>{

  //     try {
  //       await fetchUserData(user).then(() =>{
  //         console.log(avlUser);
  //       })
  //     }catch (error){
  //       console.log("Error handling fetchdata",  error);
  //     }
  // }

  // fetchData()
  // }, [])

  console.log(bioData);


 useEffect(() =>{
  const storedUserData = localStorage.getItem('userData')
  setAvlUser(JSON.parse(storedUserData))
 },[])



console.log(avlUser);
  if (!user) {
    navigate("/login")
  } else {

  return  (
  
    <div>
       {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (
        <>
        <Navbar/>
        <div className="user-info">
          <div className="name-and-profile-pic">
            <img className="profile-pic" src={avlUser && avlUser.photo ? avlUser.photo : (user.photoURL? user.photoURL : ProfilePic)} alt='profile'/>{ avlUser ? (<AiOutlineEdit className="edit-icon" onClick={togglePhotoEdit}/>) : ""}
            {editProfilePicture ? (<form onSubmit={handleSubmit}>
              <input type="file" accept="image/*" onChange={ async (e) => {
      try {
        const file = e.target.files[0];
        setPic(await resizeFile(file))
        console.log(pic);
      } catch (err) {
        console.log(err);
      }
    }
}/>
              <button type='submit'>Update</button>
            </form>) :
             null}
            <div>
            <h3 className="user-name">{user ? user.displayName: "You are not Enrolled"}</h3>
            <p className="enrolled-as">{avlUser ? `Enrolled In the ${avlUser.level} Level` : "You are not enrolled yet"} </p>
            </div>
          </div>
          <div className="bio-wrapper">
            <h2 className="bio-header">Bio</h2>
            <p className="bio">{avlUser? avlUser.bio : "your Bio would be editable when you enroll for a course"}{avlUser ? (<AiOutlineEdit className="edit-icon" onClick={toggleBioEdit}/>) : ""}</p>
            {editBio ? (
              <form onSubmit={handleBioSubmit}>
                <input type='text' value={bioData} onChange={(e) => setBioData(e.target.value)}/>
                <button type='submit'>Update</button>
              </form>
            ): null }
          </div>
        </div>
         
        {avlUser?
        <>
        <div className="course-progression">

        <div className='progression-wrap'>
          <h2 className="course-progression-header">Course Progression</h2>
          <div className="progression-card">
            <h3 className="level">{avlUser? avlUser.level : ""} Progression</h3>
            <p className="progress-bar">You have completed <span className="progress-percentage"> 1% </span>of your course</p>
            <Link to={avlUser? `${avlUser.level}-curriculum` : ""}><button className="unto-the-next" >See Curriculum</button></Link>
          </div>
        </div>  

        <div className="join-card">
          <h3 className="level">Head To Community🥳🥳!</h3>
          <p className="progress-bar">Get the most off this course</p>
          <Link to={'https://t.me/+6D67N5uiQQY2ZDY0'}><button className="unto-the-next" >Telegram</button></Link>
        </div>
        </div>
        <div className='certificates'>
          <h2 className='certificates-header'>Certificates</h2>
          <div className='the-certificates'>
          <div className='dummy-certificate1'></div>
          <div className='dummy-certificate2'></div>
          </div>
        </div>
        </>
        : <>
        <div className='not-user'>
          <p>You need to be enrolled to get the full package head to the enrollment page</p>
          <button className='redirect-to-enroll' ><Link to={'../course'}>Lets Go</Link></button>
        </div>
        </>}
        </>
      )}  
        
    </div>
  ) 

  }
}
