
// Import the functions you need from the SDKs you need  
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
// import "firebase/storage"



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey:process.env.REACT_APP_API_KEY,
  authDomain:process.env.REACT_APP_AUTH_DOMAIN,
  projectId:process.env.REACT_APP_PROJECT_ID,
  storageBucket:process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId:process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId:process.env.REACT_APP_APP_ID,
  measurementId:process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase {this is an actual comment, but more like a header comment, lol.}//

// const app = initializeApp(firebaseConfig);--- Might use this somewhere probably


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const storage = getStorage(app);
// export const messaging = getMessaging(app);

// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default auth;