const basicCourseContentData = [
    {
      header : "Introduction to Web3",
      classPeriod : "2024-04-17",
      isCompleted: false,
    },
    {
      header : "Introduction to Blockchain",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "History of money",
         "Meaning of blockchain",
         "Types of blockchain",
         "Examples of blockchain (focus on Bitcoin)"
      ]
    },  
    {
      header : "Meaning of Cryptocurrency",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Types of Cryptocurrency",
         "Meaning of blockchain",
         "Examples of Cryptocurrency (Give assignment. Identify 5 Cryptocurrency and their blockchain. Identify 5 crypto token and their protocol)",
      ]
    },  
    {
      header : "Centralized Finance (Cefi)",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning of Cefi",
         "Examples of Cefi platforms -(Centralized lending platforms and centralized exchanges.)"
      ]
    },  
    {
      header : "Decentralised Finance (Defi)",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning of Defi",
         "Assignment (discuss the history of Defi and the founder of Defi)",
         "Examples of Defi platforms (exchanges, lending)",
      ]
    }
  ]

  export default basicCourseContentData