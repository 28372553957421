

const intermediateCourseContentData = [
    {
      header : "Analysis of different blockchains. and their crypto currency usecases, (focusing on Bitcoin, Cardano, Eth, EOS, Tron and Hedera)",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
        "Assignment: (differentiate between Bitcoin and Tron Blockchain. Identifying the blockchain type, crypto asset name, the history of the networks and the unique properties of each network)."
      ]
    },
    {
      header : "Stable Cryptocurrency",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Types and examples",
      ]
    },  
    {
      header : "Cryptocurrency wallets",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning and features",
         "Types of wallets",
         "Examples of wallets based on types.",
         "How to create Cryptocurrency wallet",
         "How to send and receive crypto in your wallet",
      ]
    },  
    {
      header : " What is a crypto currency exchange",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         " (Assignment; Differentiate between centralized and decentralised crypto exchange with two examples each.",
      ]
    },  
    {
      header : "Crypto trading",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning of and ways of crypto trading",
         "Types of crypto trading (spot, futures, pepertual contract, margin, p2p)",
         "(Assignment; create an account on OKX, fund with atleast $10, buy ETH and sell at profit).",
      ]
    }
  ]

  export default intermediateCourseContentData