import React, { useState } from 'react'
import "./Curriculum.css"
import Navbar from '../Navbar/navbar'
import Footer from '../footer/Footer'
import AdvanceCourseData from '../courses/AdvancedCourseContentData'
import { IoCheckmarkDoneSharp } from "react-icons/io5";







export default function AdvanceCurriculum() {

    const [isCompleted, setIsCompleted] = useState(false)
    const [classPeriod, setClassPeriod] = useState()

    const curriculumItem = AdvanceCourseData.map((item, index) => {
        const classPeriodDate = new Date(item.classPeriod);
        const currentDate = new Date();
        // console.log("currentDate:", currentDate);
        // console.log("classPeriodDate:", classPeriodDate);
    
        let classStatus = '';
    
        if (classPeriodDate.getFullYear() === currentDate.getFullYear() && classPeriodDate.getMonth() === currentDate.getMonth() && classPeriodDate.getDay() === currentDate.getDay()) {
            classStatus = 'live';
        } else if (classPeriodDate.getTime() > currentDate.getTime()) {
            classStatus = 'upcoming';
        } else {
            classStatus = 'done';
        }
    
        console.log("classStatus:", classStatus);
    
        let actionButton = '';
    
        if (classStatus === 'live') {
            actionButton = <button type="button">join</button>;
        } else if (classStatus === 'upcoming') {
            actionButton = 'Upcoming';
        } else if (classStatus === 'done') {
            actionButton = <IoCheckmarkDoneSharp />;
        }
    
        console.log("actionButton:", actionButton);
    
        return (
            <React.Fragment key={index}>
                <div>
                <h3 id='header' className={classStatus}>
                    {item.header}
                </h3>
                <p className={classStatus} id='class-period'>{item.classPeriod}</p>
                <div className={classStatus} id='action-btn'>{actionButton}</div>
                </div>
                <hr />
            </React.Fragment>
        );
    });
  return (
    <div >
        <Navbar/>
        <div className="curriculum">
            <h1 className="your-enrollment-header">ADVANCE LEVEL ENROLLED</h1>
            <div className="course-curriculum">
                <h3 className="course-content-header"> Course Content</h3>
                <ul className="curriculum-content">
                  {curriculumItem}
                </ul>
            </div>
        </div>
        
        <Footer/>
    </div>
  )
}
