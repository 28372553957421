import React, {useEffect, useState, CSSProperties} from 'react'
import Navbar from '../Navbar/navbar'
import Footer from '../footer/Footer'
// import { User } from 'firebase/auth'
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth, db, firestore} from '../../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import "./enroll.css"
import Signup from '../auth/signup';
import {useLevelSelection} from "./LevelSelectionContext"
import { useAvlUser, useEnrollment, useFetchData, useLoading, useUser } from '../../utils/FirestoreContext';
import { onAuthStateChanged, updateCurrentUser } from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';
// import { css } from '@emotion/react';
import ClipLoader from "react-spinners/ClipLoader";
import BasicCourse from '../courses/BasicCourse';
import Alert from '@mui/material/Alert';
import PopUp from './popup';
import { Helmet } from 'react-helmet';
import logo from '../Navbar/images/logo.svg'




const override = {
  display: "block",
  margin: "300px auto",
  borderColor: "#8B03FB",
};


export default function EnrollBasic( reRoutToDashboard, setreRoutToDashboard, props) {
    const [authUser] = useAuthState(auth)
    const [user, enrollUser] = useUser()
    const [selectedLevel, setSelectedLevel] = useLevelSelection()
    const [enrollmentError, setEnrollmentError] = useState("")
    const [avlUser, setAvlUser] = useAvlUser()
    const [loading, setLoading] = useLoading();
    const fetchUserData = useFetchData()
    const [userData, storedUserData] = useState()
    const [isSuccess, setIsSuccess] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)
    // const [fetchUserData] = useFirestore()



    const navigate = useNavigate()


    // if (!user){
    //   return null;
    // }
  useEffect(()=>{
    setSelectedLevel("Basic");
    // setAvlUser(avlUser)
    // console.log(avlUser)
  }, [selectedLevel])
    
    // setUserIsEnrolled(true)
    
    const handleEnrollment = async () => {
      if(!selectedLevel) {
        setEnrollmentError("please select a level")
        return;
      };
      await enrollUser(db).then((() => {
        fetchUserData()
        console.log(localStorage.getItem('userData'))
        // localStorage.setItem('userData', JSON.stringify(avlUser))
        console.log(avlUser)
        // setAvlUser(avlUser)
      })).then(()=>{
        setIsSuccess(true)
      })
    };


    useEffect(() => {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        console.log(storedUserData);
        try {
          setAvlUser(JSON.parse(storedUserData));
          console.log(avlUser);
        } catch (error) {
          console.error("Error parsing stored user data:", error);
          // Handle the error, perhaps remove the invalid data from localStorage
          localStorage.removeItem('userData');
        }
      } else {
        fetchUserData(); // Fetch the data if not found in localStorage
      }
    },[])

    useEffect(() =>{
      setShowPopUp(true)
    }, [PopUp])

    return (
      <div>
        <Helmet>
        <title>Blockbit Institute | Your Personalised Blockchain Institute</title>
        
        {/* General meta tags */}
        <meta name="description" content="Your personalised person to person institute. A platform to learn all things Web3." />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="blockchain, blockchain courses, blockchain certification, learn blockchain, crypto enthusiats, Learn crypto, make money from crypto, get certified, get a blockchain certificate" />
        <meta name="author" content="Blockbit Institute" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Language" content="en" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content="Blockbit Institute | Your Personalised Blockchain Institute" />
        <meta property="og:description" content="Your personalised person to person institute. A platform to learn all things Web3." />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://www.blockbit.institute" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content={logo} />
        <meta name="twitter:title" content="Blockbit Institute | Your Personalised Blockchain Institute" />
        <meta name="twitter:description" content="Your personalised person to person institute. A platform to learn all things Web3." />
        <meta name="twitter:image" content="https://www.blockbit.institute" />
        </Helmet>
        {showPopUp && (<PopUp/>)}
        {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (
        <>
            <Navbar />
              <>
                <div className="enroll-basic">
                  <h2 className="enroll-basic-header">Basic Level Enrollment</h2>
                </div>
                {isSuccess && (<Alert severity="success">Successfully Enrolled to the basic level<br/> Head to your profile to continue.</Alert>)}
                <BasicCourse/>
              </>
            {/* {!authUser ? (
              <Signup reRoutToDashboard={false} />
            ) : (
              <div className={loading ? "none" : "enroll-button-wrapper"}>
                {avlUser ? "" :(<button onClick={() => { handleEnrollment(); }}
                 className="enroll-basic-btn"
                >
                  Enroll Basic
                </button>)}
              </div>
            )} */}
          <Footer />  
        </>
        )}
      </div>
    );    
}
