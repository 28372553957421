import React from 'react'
import CourseProgression from './CourseProgression'
import Footer from "../footer/Footer"
import Navbar from '../Navbar/navbar'
import "./courses.css"

export default function ChooseCoursePath() {
  return (
    <div>
        <Navbar/>
        <div className="choose-path">
            <h2 className="choose-path-header">Welcome!</h2>
            <p className="choose-path-text">Pick a progression you would like to start as</p>
            <CourseProgression className="course-progression"/>
        </div>
        <Footer/>
    </div>
  )
}
