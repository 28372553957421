import React from 'react'
import {AiOutlineCheck} from "react-icons/ai"
import {Link} from "react-router-dom"
import "./courses.css"
import Footer from "../footer/Footer"
import CourseContent from './course-content'
import IntermediateCourseData from './IntermediateCourseContentData'



export default function IntermediateCourse({props, courseData, CourseContentHeader}) {


  return (
    <div>
        <div className="course">
            <div className="course-description">
                <h2 className="course-description-header">Course Description</h2>
                <h3 className="what-you-would-learn-header">At the end of this course, students will have basic knowledge and demonstrate understanding of the following</h3>
                <div className="learning-outcome">
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">How to analyse and understand different blockchains and thier usecases</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">The meaning of stable currencies and their usecases </li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">How crypto wallets work and how to interact with them</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">How to navigate the world of centralised and Decentralised exchange</li></div>
                    <div className="each"><AiOutlineCheck className="check"/> <li className="you-will-learn">understand crypto trading and how to engage</li></div>
                </div>    
            </div>
        </div>
        <CourseContent
        CourseContentHeader = "Intermediate Course --(30 hours)"
        courseData={IntermediateCourseData}
        />
    </div>
  )
}
