import React from "react";
import "../about/about.css"
import Card from "./components/core-values-card";
import "../about/components/card-data"
import Footer from "../footer/Footer";
import brilliance from "./images/brilliancePNG.png"
import innovation from "./images/innovationPNG.png"
import transperency from "./images/transperencyPNG.png"
import commitment from "./images/commitmentPNG.png"
import originality from "./images/originalityPNG.png"
import intergrity from "./images/intergrityPNG.png"
import nobility from "./images/nobilityPNG.png"




export default function About() {

    return(
        <div className="about">
            <div className="about-section">
            <div className="experience">
                <h2 className="our-experience-header">What We Do</h2>
                <p className="our-experience-details" id="our-experience-details-dekstop">
                Blockbit Institute, through its tutors, has contributed immensely to the growing blockchain and Cryptocurrency space in Nigeria.
                The contributions stem from teaching students about the essence of the technology and it's potentials in creating a better working system in various sectors. 
                The Institute has also served as a mentorship ground for people interested in finding a career pathway within the Web3 ecosystem. 
                This is well thought out as the mentors guide the mentees  on how to develop themselves and the right approach in seeking jobs/engagements. 
                The Institute has also actively led the crusade on blockchain adoption by creating educational and professional content through its tutors to large audiences using various social platforms like LinkedIn, Twitter, Facebook, Telegram, Discord etc. 
                Some of our tutors are actively engaged in blockchain projects at various
                capacities such as executive positions, business development, marketing leads, operations, community management, events speaking and management, 
                </p>
                <p className="our-experience-details" id="our-experience-details-mobile" >
                Blockbit Institute, through its tutors, has contributed immensely to the growing blockchain and Cryptocurrency space over the years.
                The contributions stem from teaching students about the essence of the technology and it's potentials in creating a better system in various sectors.
                The Institute has also served as a mentorship ground for people interested in finding a career path with the Web3 ecosystem.
                </p>

            </div>

            <div className="goal">
                <div className="our-goal">
                    <h2 className="goal-header">Our Goal</h2>
                    <p className="our-goal-detials">To lead a comprehensive, inclusive and world class crypto and blockchain education in Africa and beyond</p>
                </div>
            </div>

            <div className="mission">
                <div className="mission-statement">
                    <h2 className="mision-header">Mission Statement</h2>
                    <div className="mission-detials">
                        <p className="mission-details-dekstop"> To bring students up to speed in the global space of blockchain and cryptocurrency adoption through simplified learning</p>
                        <p className="span">To teach and equip students with the needed skillset to leverage on the potentials/opportunities within the blockchain ecosystem</p>
                        <p className="span">To focus on practical aspects of the course material in order to make learning meaningful and stimulating</p>
                        <p className="span">To collaborate with relevant stakeholders, institutions and organizations in furtherance of the blockchain and crypto adoption crusade.</p>
                    </div>
                </div>
            </div>

            <div className="core-values-cards">
                <h2 className="core-value-main-header"> Core Values</h2>
                <div className="card-circle1"></div>
                <div className="card-circle2"></div>
                <div className="card-circle3"></div>
                <div className="card-circle4"></div>
                <div className="card-circle5"></div>
                <div className="cards">
                    {/* Kindly note that classess for the cards are defined in the components and styled with about.css */}
                   <Card 
                   cardHeader = "Brilliance"
                   cardDetails = "Most brilliance arises from ordinary people working together in extraordinary ways. Join blocbit institue today"
                   cardImage = {brilliance}
                   /> 
                   <Card 
                   cardHeader = "Innovation"
                   cardDetails = "Equip you with the right knowledge to give you the needed capacity to tap into a new sphere of wealth and individual freedom"
                   cardImage = {innovation}
                   /> 
                   <Card 
                   cardHeader = "Transparency"
                   cardDetails = "We make sure to expose the values of Blockchain while highlighting the risks associated with trading or investing in crypto assets"
                   cardImage = {transperency}
                   /> 
                   <Card 
                   cardHeader = "Commitment"
                   cardDetails = "If you had started doing anything two weeks ago, by today you would have been two weeks better at it. START NOW"
                   cardImage = {commitment}
                   /> 
                   <Card 
                   cardHeader = "Originality"
                   cardDetails = "Fuels the foundation of Blockchain/Crypto, driving transformative solutions and pioneering a decentralized future for you all"
                   cardImage = {originality}
                   /> 
                   <Card 
                   cardHeader = "Integrity"
                   cardDetails = "We uphold Integrity as a foundational value, ensuring transparency, trustworthiness, and ethical conduct in all our endeavors."
                   cardImage = {intergrity}
                   /> 
                   <Card 
                   cardHeader = "Nobility"
                   cardDetails = "We uphold nobility as a core value, fostering a culture of honour, virtue and excellence in all our pursuits"
                   cardImage = {nobility}
                   /> 
                </div>
            </div>

            </div>    


            {/* <div className="about-section-two">
                <div className="associates">
                <h2 className="blockbit-partners-header"> Blockbit Partners</h2>
                </div>
            </div> */}
            <Footer/>
        </div>
    )
}