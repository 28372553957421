const advancedCourseContentData = [
    {
      header : "Web3 and Web2: where they meet",
      classPeriod : "2024-04-16",
      isCompleted: false,
      
    },
    {
      header : "Social Finance (SocialFi)",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Assignment: Discuss",
      ]
    },  
    {
      header : "What is NON-Fungible Token (NFT)?",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning and feature",
         "How does it work?",
         "Difference between Fungible tokens and NFT.",
      ]
    },  
    {
      header : "SocialFi",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning of SocialFi",
         "Types of SocialFi",
         "The raising trends in SocialFi"
      ]
    },  
    {
      header : "What is a Metaverse?",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "History of Metaverse",
         "Features of Metaverse and how it works",
         "What is the relationship between Blockchain and Metaverse",
         "Gamified Protocols",
      ]
    },
    {
      header : " Understanding blockchain use cases",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Meaning",
         "Types",
         "Medical use case",
         "Identity management",
         "Supply chain management",
         "Land and Real Estate management",
      ]
    },
    {
      header : "Understanding Blockchain in Finance",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
      ]
    },
    {
      header : "Introduction to Smart Contracts",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Create and Deploy Basic Smart Contracts.",
         "Deploying Smart Contracts on Public Networks.",
         "How to Verify Smart Contracts on Etherscan.",
         "Smart Contracts Auditing and its Benefits.",
      ]
    },
    {
      header : "Decentralized Identity (DID) - Meaning, features and advantage",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
         "Soulbound Tokens",
         "World Coin",
      ]
    },
    {
      header : "Careers in Blockchain",
      classPeriod : "2024-04-17",
      isCompleted: false,
      learningOutcome : [
      ]
    },{
      header : "PROJECT"
    }
  ]

  export default advancedCourseContentData