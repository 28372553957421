import React from "react";
// import Brilliance from "../images/brilliance.svg"
import "../about.css"
import "./card-data"
// import cardData from "./card-data";




export default function Card (props){

    return(
        <div className="each-card">
            <div className="card-text-wrapper">
                <h2 className="value-header">{props.cardHeader}</h2>
                <p className="card-details">{props.cardDetails}
                </p>
            </div>
            <div className="card-image-wrapper">
                <img className="card-image" src={props.cardImage} alt="Our core value cards"/>
            </div>
        </div>
    )
}