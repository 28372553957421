import React from 'react'
import "./navbar.css"
import { useState } from "react";
import Hamburger from "./images/hamburger.svg"
import Profile from "./images/profile.svg"
import Graduate from "./images/Graduate.svg"
import Books from "./images/Books.svg"
import Teacher from "./images/Teacher.svg"
import Cancel from "./images/cancel-icon.svg"
import {Link} from "react-router-dom"
import logo from "./images/logo.svg"
import { useAuthState } from 'react-firebase-hooks/auth';
import {auth} from "../../utils/firebase"
// import Login from '../auth/login';
// import backgroundImage from "../blog/images/unsplash_505eectW54k.svg"






export default function Navbar(props) {
    //const [user, loading] = useAuthState(auth) we can use the loading for when the app is still trying to laod in user
     
    // const navStyle = {
    //     backgroundImage : `url(${backgroundImage})`,
    //     backgroundSize: 'cover', // You can adjust these properties based on your design requirements
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    //     objectFit: 'cover',
    // }

    const [menuOpen, setMenuOpen] = useState(false)
    const [user] = useAuthState(auth)


  return (
    <div>
        <nav className="home-page-navbar">
                <div className="hamburger-menu">
                    <img className="hamburger" src={Hamburger} alt="" onClick={()=>{
                    setMenuOpen(!menuOpen)
                    }}/>
                </div>
            
                <div className="logo-wrapper">
                    <Link to='/'>
                    <img src={logo} alt="logo"/>
                    </Link>
                </div>
                <ul className="nav-items" id={menuOpen ? "open" : "close"}>
                    <div className="cancel-menu">
                       <img className="cancel" alt="" src={Cancel} onClick={()=>{
                        setMenuOpen(!menuOpen)
                       }}/>
{/* 
!user && ('/login') || user && ('/profile') */}

                    </div>
                    <li className="nav-item" id="first-nav-item"><img alt="nav-icon" src={Profile}/><Link to={user ? "/profile" : "/Login"}>Profile</Link></li>
                    <li className="nav-item"><img alt="nav-icon" src={Books}/><Link to={"/course"}>Course</Link></li>
                    <li className="nav-item"><img alt="nav-icon" src={Graduate}/><Link>Alumni</Link></li>
                    {/* <li className="nav-item"><img alt="nav-icon" src={Teacher}/><Link to={"/mentors"}>Mentors</Link></li> */}
                    <li className="nav-item"><img alt="nav-icon" src={Teacher}/><Link to={"/blog"} >Blog</Link></li>
                </ul>
            </nav>
    </div>
  )
}
