import React from 'react'
// import "login.css"
import { Link, useNavigate } from 'react-router-dom'
import "./auth.css"
import {FaGoogle} from "react-icons/fa"
import {FaFacebook} from "react-icons/fa"
import {GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword} from "firebase/auth"
import auth from "../../utils/firebase"
import { useEffect, useState} from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useAvlUser, useFetchData, useLoading } from '../../utils/FirestoreContext'
import ClipLoader from 'react-spinners/ClipLoader'



const override = {
    display: "block",
    margin: "300px auto",
    borderColor: "#8B03FB",
  };


export default function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailOrPasswordError, setEmailOrPasswordError] = useState(false)
    const [loading, setLoading] = useLoading()
    const fetchUserData = useFetchData()
    const [avlUser, setAvlUser] = useAvlUser()

    //sign in with google

    const navigate = useNavigate()
    const googleProvider = new GoogleAuthProvider();
    const GoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth,googleProvider)
            console.log(result.user)
            navigate("/profile")
            
        } catch (error) {
            console.log("error")
        }
    }

    // const [user, loading] = useAuthState(auth); the loading can be used when we are awaiting or user is still loading..


    const [user] = useAuthState(auth);

    useEffect( () =>{
        fetchUserData()
        console.log(avlUser)
        if(user){
            navigate("/profile")
        }
    }, [user, navigate]
    );


const EmailSignIn = async (e) => {
    e.preventDefault()
    try {
        setLoading(true)
        await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            console.log(userCredential)
        })
    setLoading(false)    
    } catch (error) {
        setLoading(false)
        console.log(error)
        setEmailOrPasswordError(true)
    }

}

  return (
    <div className="login-screen-wrapper">
        {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (
        <>
        <div className="login">
            <div className="sign-in-options">
                <p className="signin-choice-text">Sign in with one of these options:</p>
                <button className="sign-in-with-google" onClick={GoogleLogin} >
                    <FaGoogle/> Sign in with Google 
                </button>
                {/* <button className="sign-in-with-facebook">
                    <FaFacebook/> Sign in with Facebook
                </button> */}
            </div>
            <form action="" onSubmit={EmailSignIn} className="login-form">
                <div className="email-login">
                <label htmlFor="email">Email:</label>
                <input type="email" name="Email" id="login-email" value={email} onChange={(e)=>(setEmail(e.target.value))} placeholder="e.g blockbit@gmail.com"/>
                </div>
                <div className="password-login">
                <label htmlFor="password">Password:</label>
                <input type="password" placeholder="******" onChange={(e) => (setPassword(e.target.value))} value={password} id="login-password"/>
                {emailOrPasswordError ? <div> <p className="email-and-pass-error">Invalid Email or Password</p></div> : ""}
                </div>
            <button className="continue-to-signin" type="submit">Continue</button>

            </form>
            {/* <button className="continue-to-signin" type="submit">Continue</button> */}
        </div>
        <div className="alternatively-signup">
            <Link to="" className="forgot-password">Forget password?</Link>
            <Link to="/signup" className="new-sign-up">Sign Up</Link>
        </div>
        </>)}
    </div>
  )
}






//  will continue from here if nessecary

//  sign in with facebook
// const fbProvider = new FacebookAuthProvider();
// const FacebookLogin = async () => {
//     try {
//         await signInWithPopup(auth, fbProvider)
//         console.log(result);
//         navigate("/profile")
//     } catch (error) {
//         console.log(error)
//     }
// }